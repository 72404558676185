<template>
  <md-card class="md-p-0 md-m-0">
    <md-card-header class="md-p-0 md-m-0">
      <md-toolbar class="md-m-0 md-pt-0 md-pb-0">
        <h5 class="md-title" style="flex: 1">
          {{ feature.properties.name }}
        </h5>
        <md-button
          class="md-icon-button"
          @click="
            () => {
              this.$emit('click', true);
            }
          "
        >
          <md-icon class="md-raised md-accent">
            cancel
          </md-icon>
        </md-button>
      </md-toolbar>
    </md-card-header>
    <md-card-content>
      <table>
        <tbody>
          <tr class="md-table-row">
            <td class="md-table-cell">
              <div class="md-table-cell-container">
                Óbitos:
                {{ feature.properties.deaths }}
              </div>
            </td>
          </tr>
          <tr class="md-table-row">
            <td class="md-table-cell">
              <div class="md-table-cell-container">
                Incidencia: {{ feature.properties.incidence }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  props: {
    feature: Object
  },
  mounted() {}
};
</script>

<style></style>
