<template>
  <div class="content md-mt-0 md-pt-0">
    <div class="md-layout">
      <stats-card>
        <template slot="header">
          <div
            class="card-icon md-elevation-5"
            style="margin-right: auto;"
            :style="
              `background: linear-gradient(60deg, rgba(153, 153, 153, 0.5) 1%, ${dataSet.color} 95%)`
            "
          >
            <md-icon>map</md-icon>
          </div>
          <p class="category">Total AIH {{ dataSet.total | locateNumber }}</p>

          <h3 class="title">
            {{ dataSet.year }}
          </h3>
        </template>
        <template slot="content">
          <md-app md-mode="reveal">
            <md-app-toolbar class="md-primary md-dense" md-elevation="5">
              <div class="md-toolbar-section-start">
                <h4 class="md-mt-0 md-mb-0">
                  Mapa de atendimento de residentes e não residentes
                </h4>
              </div>
              <div class="md-toolbar-section-end">
                <md-button class="md-raised md-white" @click="() => {}">
                  Download PNG
                </md-button>
                <a id="image-download" download="map.png"></a>

                <md-button
                  class="md-raised"
                  @click="setVisible"
                  v-if="!menuVisible"
                >
                  <md-icon>filter_alt</md-icon>
                </md-button>
              </div>
            </md-app-toolbar>

            <md-app-drawer :md-active.sync="menuVisible" md-right>
              <md-toolbar class="md-primary" md-elevation="5">
                <div class="md-toolbar-section-start">
                  <md-button
                    class="md-icon-button md-dense"
                    @click="setVisible"
                  >
                    <md-icon>keyboard_arrow_right</md-icon>
                  </md-button>
                </div>
                <span>Filtros</span>
              </md-toolbar>
              <filter-cid-card @update="setCids"></filter-cid-card>
              <md-list class="md-dense">
                <md-list-item style="color: #CC6532;">
                  <md-switch v-model="incidence" @change="coloringMap" />
                  <md-icon style="color: #CC6532;">reduce_capacity</md-icon>
                  <span class="md-list-item-text">Incidência</span>
                </md-list-item>
              </md-list>
            </md-app-drawer>
            <md-app-content>
              <vl-map
                :load-tiles-while-animating="true"
                :load-tiles-while-interacting="true"
                data-projection="EPSG:4326"
                style="height: 600px"
                ref="map"
              >
                <vl-view
                  :zoom.sync="zoom"
                  :center.sync="center"
                  :rotation.sync="rotation"
                ></vl-view>

                <vl-layer-tile id="osm">
                  <vl-source-osm></vl-source-osm>
                </vl-layer-tile>

                <vl-layer-vector>
                  <vl-source-vector
                    ref="layerRegions"
                    :features.sync="regionsFeatures"
                  >
                  </vl-source-vector>
                  <vl-style-box>
                    <vl-style-stroke
                      color="rgba(255,255,255,0.5)"
                      :width="1"
                    ></vl-style-stroke>
                  </vl-style-box>
                </vl-layer-vector>

                <vl-layer-vector>
                  <vl-source-vector
                    ref="featureOverlay"
                    :features.sync="featureOverlay"
                  >
                  </vl-source-vector>
                  <vl-style-box>
                    <vl-style-stroke
                      color="rgba(255,255,255,0.5)"
                      :width="3"
                    ></vl-style-stroke>
                    <vl-style-fill
                      color="rgba(255,255,255,0.3)"
                    ></vl-style-fill>
                  </vl-style-box>
                </vl-layer-vector>

                <vl-overlay
                  v-for="resident in residentsOverlay"
                  :key="resident.munic_mov"
                  :id="resident.munic_mov"
                  class="feature-popup"
                  :position="resident.position"
                  :auto-pan="true"
                  :auto-pan-animation="{ duration: 300 }"
                  v-show="displayOverlay"
                >
                  <template>
                    <PieChartOverlay :feature="resident"></PieChartOverlay>
                  </template>
                </vl-overlay>

                <!-- interactions -->
                <vl-interaction-select :features.sync="selectedFeatures">
                  <template slot-scope="selection">
                    <vl-overlay
                      v-for="feature in selection.features"
                      :key="feature.id"
                      :id="feature.id"
                      class="feature-popup"
                      :position="pointOnSurface(feature.geometry)"
                      :auto-pan="true"
                      :auto-pan-animation="{ duration: 300 }"
                    >
                      <template>
                        <OverlayCard
                          :feature="feature"
                          @click="
                            selectedFeatures = selectedFeatures.filter(
                              f => f.id !== feature.id
                            )
                          "
                        ></OverlayCard>
                      </template>
                    </vl-overlay>
                    <!--// selected popup -->
                  </template>
                </vl-interaction-select>
                <div class="layer-legend ol-viewport">
                  <div class="list">
                    <md-list :md-expand-single="true">
                      <md-list-item
                        md-expand
                        :md-expanded="true"
                        class="md-p-0"
                      >
                        <md-icon>info</md-icon>
                        <span class="md-list-item-text">Óbitos</span>

                        <md-list slot="md-expand">
                          <md-list-item
                            v-for="(item, index) in ranges"
                            :key="index"
                            class="md-m-0 md-p-0"
                          >
                            <md-icon :style="`color: ${colors[index]}`"
                              >map</md-icon
                            >
                            <span class="md-list-item-text">
                              {{ item[0] }}|- {{ item[1] }}
                            </span>
                          </md-list-item>
                        </md-list>
                      </md-list-item>
                    </md-list>
                  </div>
                </div>
              </vl-map>
            </md-app-content>
          </md-app>
        </template>
        <template slot="footer">
          <div class="stats">
            <md-icon>today</md-icon>
            Atualizado em: {{ dataSet.updated_at | formatDate }}
          </div>
        </template>
      </stats-card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueLayers from "vuelayers";
import { StatsCard, FilterCidCard } from "@/components";
import { createStyle, findPointOnSurface } from "vuelayers/lib/ol-ext";
import { Fill, Stroke, Style, Text } from "ol/style";
import { kebabCase } from "lodash";
import OverlayCard from "./OverlayCard";
import PieChartOverlay from "./PieChartOverlay";
import * as ss from "simple-statistics";
import hexToRgba from "hex-to-rgba";

Vue.use(VueLayers, {
  dataProjection: "EPSG:4326"
});
export default {
  components: {
    StatsCard,
    OverlayCard,
    PieChartOverlay,
    FilterCidCard
  },
  props: {
    marker_health_unit: {
      type: String,
      default: require("@/assets/img/health_unit.png")
    }
  },
  computed: {
    loggedIn: function() {
      return this.$store.state.auth.status.loggedIn;
    },
    user: function() {
      return this.$store.state.auth.user;
    }
  },
  data() {
    return {
      filterItem: null,
      colors: this.$sunSet,
      selectedCids: [],
      selectedChaptersCids: [],
      selectedGroupsCids: [],
      displayOverlay: true,
      incidence: false,
      arrow: false,
      region: { name: "" },
      source: this.$route.params.source,
      type: this.$route.params.type,
      id: this.$route.params.id,
      location_type: this.$route.params.location_type
        ? this.$route.params.location_type
        : null,
      location_id: this.$route.params.location_id
        ? this.$route.params.location_id
        : null,
      dataSet: { year: null, alias: null },
      zoom: 7.1,
      center: [-43.0691055, -6.8420932],
      rotation: 0,
      geolocPosition: undefined,
      menuVisible: false,
      markersClustersDeath: [],
      origins: [],
      regionsFeatures: [],
      selectedFeatures: [],
      featureOverlay: [],
      residentsOverlay: [],
      rangesCount: [],
      rangesIncidence: [],
      ranges: [],
      dataDeaths: [],
      fillGray: new Fill({ color: "rgba(255,255,255,0.3)" })
    };
  },
  watch: {
    cluster: function(val) {
      if (val == false) {
        this.arrow = true;
      }
    }
  },
  async created() {
    //this.getDataSet();
  },
  async mounted() {
    await this.getLayerMap();
    await this.setEventMap();
    await this.getDataMapDeath();
  },
  async beforeMount() {},
  methods: {
    setCids(selectedCids) {
      if (selectedCids.type == "cids") {
        this.selectedCids = [...selectedCids.data];
        this.getDataMapDeath();
      } else if (selectedCids.type == "chaptersCids") {
        this.selectedChaptersCids = [...selectedCids.data];
        this.getDataMapDeath();
      } else if (selectedCids.type == "groupsCids") {
        this.selectedGroupsCids = [...selectedCids.data];
        this.getDataMapDeath();
      }
    },
    setVisible() {
      this.menuVisible = !this.menuVisible;
    },
    setFilters(params) {
      if (this.selectedCids.length > 0) {
        let filters_cid = [];
        for (const cid of this.selectedCids) {
          filters_cid.push(cid.code);
        }
        params.column_filters = "causabas";
        params.term_filters = filters_cid;
      }

      if (this.selectedChaptersCids.length > 0) {
        if (params.column_filter_or == undefined) {
          params.column_filter_or = [];
        }
        if (params.term_filter_or_range == undefined) {
          params.term_filter_or_range = [];
        }

        for (const chapterCid of this.selectedChaptersCids) {
          params.column_filter_or.push("causabas");
          params.term_filter_or_range.push([
            chapterCid.starting_code,
            chapterCid.final_code
          ]);
        }
      }

      if (this.selectedGroupsCids.length > 0) {
        if (params.column_filter_or == undefined) {
          params.column_filter_or = [];
        }
        if (params.term_filter_or_range == undefined) {
          params.term_filter_or_range = [];
        }
        for (const groupCid of this.selectedGroupsCids) {
          params.column_filter_or.push("causabas");
          params.term_filter_or_range.push([
            groupCid.starting_code,
            groupCid.final_code
          ]);
        }
      }

      return params;
    },
    pointOnSurface: findPointOnSurface,
    geometryTypeToCmpName(type) {
      return "vl-geom-" + kebabCase(type);
    },
    selectFilter(feature) {
      return ["position-feature"].indexOf(feature.getId()) === -1;
    },
    async getDataSet() {
      let result = await Vue.$http.get(`v1/dataset/${this.id}/`);
      this.dataSet = result.data;
    },
    async getLayerMap() {
      let result = await Vue.$http.get(`v1/dataset/${this.id}/`);
      this.dataSet = result.data;

      try {
        let result = await Vue.$http.get(
          `v1/city/${this.dataSet.source}/${this.dataSet.name}/${this.dataSet.year}?type=map`
        );
        let regions = result.data;
        await regions.forEach(region => {
          this.regionsFeatures.push({
            type: "Feature",
            id: region.id,
            ibge_id_short: region.ibge_id_short,
            geometry: JSON.parse(region.geoJson),
            properties: {
              name: region.name,
              ibge_id_short: region.ibge_id_short,
              population: region.population
            }
          });
        });

        if (
          this.$refs.layerRegions !== undefined &&
          this.$refs.layerRegions.$source !== undefined
        ) {
          await this.$refs.layerRegions.$source.forEachFeature(feature => {
            feature.setStyle(
              new Style({
                stroke: new Stroke({ color: "#cccccc", width: 1 }),
                fill: new Fill({
                  color: "rgba(217,217,217, 0.5)"
                })
              })
            );
            feature.getStyle().setText(
              new Text({
                text: feature.getProperties().name,
                font: "12px Calibri,sans-serif",
                fill: new Fill({ color: "#000" }),
                stroke: new Stroke({ color: "#fff", width: 4 })
              })
            );
          });
        }
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    async setEventMap() {
      this.$refs.map.$map.on("pointermove", evt => {
        this.$refs.layerRegions.$sourceContainer.$layersContainer.forEachFeatureAtPixel(
          evt.pixel,
          f => {
            let newFeature = this.regionsFeatures.find(feature => {
              return feature.id == f.getId();
            });
            if (newFeature !== undefined) {
              this.featureOverlay = [];
              this.featureOverlay.push(newFeature);
            }
            return true;
          }
        );
      });

      this.$refs.map.$map.on("dblclick", evt => {
        this.displayOverlay = true;
        this.arrowOrigins = [];
        this.markersClustersOrigins = [];
        this.$refs.layerRegions.$sourceContainer.$layersContainer.forEachFeatureAtPixel(
          evt.pixel,
          f => {
            let selectFeature = this.regionsFeatures.find(feature => {
              return feature.id == f.getId();
            });
            if (selectFeature !== undefined) {
              this.displayOverlay = false;
              this.getDataMapOrigin(selectFeature);
            }
            return true;
          }
        );
      });
    },
    generateRangeClass(values) {
      let mode = ss.mode(values);
      let max = ss.max(values);
      let min = ss.min(values);
      let log = Math.log10(values.length);

      let ranges = [];

      for (let i = min; i <= max; i = Math.round((i + mode) * log)) {
        ranges.push([i, Math.round((i + mode) * log)]);
      }
      return ranges;
    },
    generateRangeIncidenceClass(values) {
      //let mode = ss.mode(values);
      let max = ss.max(values);
      let min = ss.min(values);

      let log = Math.log10(values.length);

      let k = Math.round(1 + 3.3 * log);
      let ranges = [];

      for (let i = min; i <= max; i = i + Math.round(max / k)) {
        ranges.push([i, i + Math.round(max / k)]);
      }
      return ranges;
    },
    async coloringMap() {
      for (const region of this.dataDeaths) {
        let feature;

        feature = await this.regionsFeatures.find(feature => {
          return feature.properties.ibge_id_short == region.codmunres;
        });

        if (
          this.$refs.layerRegions !== undefined &&
          this.$refs.layerRegions.$source !== undefined
        ) {
          let featureEl = this.$refs.layerRegions.$source.getFeatureById(
            feature.id
          );
          let fill = this.fillGray;
          if (this.incidence) {
            this.ranges = [...this.rangesIncidence];

            for (let i = 0; i < this.rangesIncidence.length; i++) {
              if (
                region.incidence >= this.rangesIncidence[i][0] &&
                region.incidence <= this.rangesIncidence[i][1]
              ) {
                fill = new Fill({ color: hexToRgba(this.colors[i], 0.7) });
                break;
              }
            }
          } else {
            this.ranges = [...this.rangesCount];

            for (let i = 0; i < this.rangesCount.length; i++) {
              if (
                region.count >= this.rangesCount[i][0] &&
                region.count <= this.rangesCount[i][1]
              ) {
                fill = new Fill({ color: hexToRgba(this.colors[i], 0.7) });
                break;
              }
            }
          }

          featureEl.setProperties({ deaths: region.count });
          featureEl.setProperties({ incidence: region.incidence });

          featureEl.setStyle(
            new Style({
              stroke: new Stroke({
                color: hexToRgba("#ba68c8", 0.1),
                width: 1
              }),
              fill: fill,
              text: new Text({
                text: feature.properties.name,
                font: "12px Calibri,sans-serif",
                fill: new Fill({ color: "#000" }),
                stroke: new Stroke({ color: "#fff", width: 4 })
              })
            })
          );
        }
      }

      //this.load = false;
    },
    async getDataMapDeath() {
      let origin;

      this.markersClustersDeath = [];

      let params = {
        per: "codmunres",
        rating: "contador",
        operation: "count",
        per_page: 2000,
        page: 1
      };

      params = this.setFilters(params);

      origin = await Vue.$http.get(
        `v1/dataset/consume/map/datasus/sim/${this.id}/`,
        {
          params: params
        }
      );

      let deaths = origin.data.data;

      let serie_count_death = deaths.map(city => {
        let feature = this.regionsFeatures.find(feature => {
          return feature.properties.ibge_id_short == city.codmunres;
        });
        feature.properties.deaths = city.count;
        return city.count;
      });

      let serie_incidence_death = deaths.map(city => {
        let feature = this.regionsFeatures.find(feature => {
          return feature.properties.ibge_id_short == city.codmunres;
        });
        city.incidence = parseFloat(
          ((city.count / feature.properties.population) * 100000).toFixed(2)
        );
        feature.properties.incidence = parseFloat(
          ((city.count / feature.properties.population) * 100000).toFixed(2)
        );
        return feature.properties.incidence;
      });

      this.rangesCount = await this.generateRangeClass(serie_count_death);
      this.rangesIncidence = await this.generateRangeIncidenceClass(
        serie_incidence_death
      );
      this.dataDeaths = deaths;
      this.coloringMap();
    },
    clusterStyleDeathFunc() {
      return function __clusterStyleFunc(feature) {
        const features = feature.get("features");

        let total = 0;
        for (let i = 0; i < features.length; i++) {
          total += features[i].values_.count;
        }

        let radius = 20;

        radius += total * 0.009;

        let style = createStyle({
          imageRadius: radius,
          strokeColor: "#fff",
          fillColor: "rgba(204,101,50,0.9)",
          text: total.toString(),
          textFillColor: "#fff"
        });

        return [style];
      };
    }
  }
};
</script>

<style scoped lang="scss">
.md-drawer {
  width: 600px;
  //max-width: calc(100vw - 125px);
}
.filters {
  max-width: 230px;
  min-width: 200px;
  max-height: 200px;
  overflow: auto;
}

.md-toolbar {
  min-height: 64px !important;
}

.filter-item {
}

.layer-legend {
  position: absolute;
  top: 10vh;
  right: 2vw;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  .list {
    .md-list {
      .md-list-item {
        background: rgba(256, 256, 256, 0.7);
        padding-left: 15px;
      }
    }
  }
}
</style>
